import './set-public-path.js'
import React from 'react';
import ReactDOMClient from 'react-dom/client';
import singleSpaReact from 'single-spa-react';
import singleSpaCanopy from 'single-spa-canopy';
import { property } from 'lodash';

function domElementGetter() {
  let webviewUi = document.getElementById('webview-ui');
  if (webviewUi) return webviewUi;

  // If the element doesn't exist then create it
  let container = document.createElement('div');
  container.setAttribute('id', 'webview-ui');
  document.body.appendChild(container);
  return container;
}

const reactLifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  loadRootComponent: () => import(/* webpackChunkName: "root-routes" */ './root.routes.js').then(property('default')),
  domElementGetter,
});

const canopyLifecycles = singleSpaCanopy({
  domElementGetter,
  childAppName: 'webview-ui',
  mainContentTransition: false,
  featureToggles: [],
});

export const bootstrap = [
  canopyLifecycles.bootstrap,
  reactLifecycles.bootstrap,
];

export const mount = [
  reactLifecycles.mount,
  canopyLifecycles.mount,
];

export const unmount = [
  reactLifecycles.unmount,
  canopyLifecycles.unmount,
];